import React, { Component } from "react";
// import OtpInput from "react-otp-input";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import {
	checkNumber,
	registerUser,
	loginUser,
	checkSession,
	checkScanned,
	fetchCode,
	checkRegister,
	ascwLogin,
	adultRegister
} from "../../../redux/actions/userActions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import OtpButton from "./OtpButton";
// import FloatingButton from "../FloatButton";
// import popupHead from "../../../assets/images/popupHead.png";
import rm10 from "../../../assets/images/rm10.png";
import ascwLogo from "../../../assets/images/ascwLogo1.png";
import goldleaf from "../../../assets/images/goldleaf.png";
import ensureLiq2 from "../../../assets/images/ensureLiq2.png";

const mySwal = withReactContent(Swal);

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			number: "",
			name: "",
			// ic : "",
			email: "",
			otp: "",
			brand: "",
			used: true
		};
	}

	timer;

	componentDidMount() {
		const { code } = this.props.match.params;
		this.props.fetchCode({ code });
		const { lastStore } = this.props.user;
		if (code && lastStore && code === lastStore) this.props.checkSession();
		if (code) {
			this.props.checkScanned({ code }, (show, brand) => {
				// console.log({ show, brand });
				if (!show) {
					// not ipp
					mySwal.fire({
						html: `
							<div style="width: 100%; height: 100%; padding: 10px;">
							<p style="font-size: 14px; font-weight: bold;">Register now to get your</p>
							<img src="${rm10}" alt="popuphead" class="img-fluid" style="width: 100%;" />
							<p style="font-weight: bold; margin: 0; padding: 0; font-size: 24px;">Instant Rebate!</p>
							
							<p style="margin-top: 25px;">Redeem the RM10 instant rebate at any participating stores</p>
							<img src="${goldleaf}" alt="goldleaf" style="position: absolute; top: 45%; left: 50%; z-index: -1; height: 50%; transform: translate(-50%, -50%);" />
							</div>
						`,
						padding: "0px",
						showClass: {
							popup: "newpopup"
						},
						confirmButtonText: "Go Now",
						width: "68%"
					});
				} else if (show && brand === "ensure") {
					// ipp ensure
					mySwal.fire({
						html: `
							<div style="width: 100%; height: 100%; padding: 10px;">
								<img src="${ensureLiq2}" alt="ensureLiquid2Bottles" class="img-fluid" style="width: 100%;" />
								<p style="font-size: 12px; font-weight: bold; margin-top: 20px">Redeem now to get 2 unit x Ensure Gold Liquid Vanilla RPB 220ml</p>
							</div>
						`,
						padding: "0px",
						showClass: {
							popup: "newpopup"
						},
						confirmButtonText: "Go Now",
						width: "68%"
					});
				}
			});
		}
		// <p style="color: white; margin-top: -40px; font-weight: bold; font-size: 14px;">Redeem the RM10 instant rebate<br />at any participating stores</p>
	}

	componentWillUnmount() {
		mySwal.close();
	}

	handleChange = e => {
		this.setState({
			...this.state,
			[e.target.id]: e.target.value
		});
	};

	// handleVerify = e => {
	// 	e.preventDefault();
	// 	const { number } = this.state;
	// 	const voucherCode = this.props.match.params.code;
	// 	const numberRegex = /^\d+$/;
	// 	if (numberRegex.test(number) && number.length > 9 && number.length < 12 && number.substr(0, 2) === "01") {
	// 		this.props.checkNumber({ number, voucherCode });
	// 	} else {
	// 		mySwal.fire("Error", "Please enter a valid phone number", "error");
	// 	}
	// };

	// handleRegister = e => {
	// 	e.preventDefault();
	// 	const { number, name, email /* , ic*/ } = this.state;
	// 	const voucherCode = this.props.match.params.code;
	// 	const regex = /\d/gi;
	// 	// const numberRegex = /^\d+$/;
	// 	if (regex.test(name)) {
	// 		// got number
	// 		mySwal.fire("Error", "Alphabets only in 'name' input", "error");
	// 	} else {
	// 		const sendThis = {
	// 			voucherCode,
	// 			number,
	// 			name,
	// 			email
	// 			// ic
	// 		};
	// 		// console.log(sendThis);
	// 		this.props.registerUser(sendThis);

	// 		// if (numberRegex.test(ic) && ic.length === 12) {
	// 		// } else {
	// 		//     mySwal.fire("Error" , "Please enter a valid IC Number" , "error");
	// 		// }
	// 	}
	// };

	// handleOtp = otp => {
	// 	this.setState({
	// 		...this.state,
	// 		otp
	// 	});
	// };

	ascwPopup = ascwToken => {
		mySwal
			.fire({
				html: `
					<div style="text-align: left; color: black;">
						<div style="text-align: center; margin-top: 10px;">
							<img src="${ascwLogo}" alt="ascw-logo" style="width: 100%" />
						</div>

						<div style="text-align: center; font-weight: bold; margin-top: 15px;">
							<h1>Welcome!</h1>
						</div>

						<div style="margin-top: 15px;">
							<div style="margin-bottom: 20px;">
								<label style="font-size: 14px; margin-left: 5px;" for="ascw-name">Name</label>
								<input type="text" id="ascw-name" name="ascw-name" class="form-control" style="border-color: black" value="${this.state.name}" />
							</div>

							<div style="margin-bottom: 20px;">
								<label style="font-size: 14px; margin-left: 5px;" for="brand">Favourite Brand</label>
								<select id="brand" name="brand" class="form-control" style="border-color: black">
									<option value="" disabled>Favourite Brand</option>
									<option value="Ensure">Ensure® Gold™</option>
									<option value="Glucerna">Glucerna®</option>
									<option value="Pediasure">PediaSure®</option>
									<option value="PEDIASURE 10+">PediaSure®+</option>
									<option value="Similac Total Comfort">Similac® Total Comfort®</option>
									<option value="Similac gain plus gold">Similac Gold Gain Plus®</option>
									<option value="Similac gain kid gold">Similac Gold Gain Kid®</option>
									<option value="SIMILAC INTELLI-PRO">Similac® Intelli Pro</option>
									<~option value="Similac mom">Similac® Mom</option>
									<option value="Isomil plus">Isomil Plus</option>
								</select>
							</div>

							<div>
								<p style="font-size: 14px;">Have you been consuming this Brand in the pass 12 months?</p>
								<div>
									<div>
										<input type="radio" id="yes" name="used" checked="true" />
										<label style="font-size: 14px;" for="used">Yes</label>
									</div>

									<div>
										<input type="radio" id="no" name="used" />
										<label style="font-size: 14px;" for="used">No</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				`,
				confirmButtonText: "Submit",
				confirmButtonColor: "#2197D3",
				allowOutsideClick: false
			})
			.then(clicked => {
				if (clicked.isConfirmed) {
					const submitName = document.querySelector("#ascw-name").value;
					const submitBrand = document.querySelector("#brand").value;
					const submitYes = document.querySelector("#yes").checked;
					// const submitNo = document.querySelector("#no").checked;
					const voucherCode = this.props.match.params.code;
					const number = this.state.number.match(/\S/gi).join("");

					if (!submitName) {
						this.ascwPopup(ascwToken);
					} else {
						const submitThis = {
							favourite_brand: submitBrand,
							pass_usage_product: submitYes,
							number,
							ascwToken,
							voucherCode
						};

						this.props.ascwLogin(submitThis);
					}
				}
			});
	};

	handleLogin = e => {
		e.preventDefault();
		const voucherCode = this.props.match.params.code;
		const { name, otp } = this.state;
		const number = "0" + this.state.number.match(/\S/gi).join("");
		// console.log({ voucherCode, number, name, otp });
		const numberRegex = /^\d+$/;

		if (numberRegex.test(number) && number.length > 9 && number.length < 12 && number.substr(0, 2) === "01") {
			if (this.props.user.action === "check") {
				if (name && name !== "") {
					const alphaRegex = /\d/gi;
					if (alphaRegex.test(name)) {
						mySwal.fire("Error", "Alphabets only in 'name' input", "error");
					} else {
						const sendThis = {
							number,
							name,
							voucherCode
						};
						this.props.checkRegister(sendThis);
					}
				} else {
					const sendThis = {
						number,
						name,
						voucherCode
					};
					this.props.checkRegister(sendThis);
				}
			} else {
				// if (this.props.user.status === "new") {
				// 	// mySwal.fire({
				// 	// 	text: "Did you use this brand in the last 6 months?",
				// 	// 	input: "radio",
				// 	// 	inputOptions: {
				// 	// 		yes: "Yes",
				// 	// 		no: "No"
				// 	// 	},
				// 	// 	inputValidator: val => {
				// 	// 		if (!val) return "Please choose an answer";
				// 	// 	},
				// 	// 	confirmButtonText: "SUBMIT",
				// 	// 	customClass: {
				// 	// 		confirmButton: "big-button btn form-control"
				// 	// 	},
				// 	// 	preConfirm: answer => {
				// 	// 		const sendThis = {
				// 	// 			number,
				// 	// 			otp,
				// 	// 			voucherCode,
				// 	// 			answer
				// 	// 		};

				// 	// 		return this.props.loginUser(sendThis);
				// 	// 	}
				// 	// })

				// 	const sendThis = {
				// 		number,
				// 		otp,
				// 		voucherCode
				// 	};
				// 	this.props.loginUser(sendThis);
				// } else {

				// 	const sendThis = {
				// 		number,
				// 		otp,
				// 		voucherCode
				// 	};
				// 	this.props.loginUser(sendThis);
				// }

				const sendThis = {
					number,
					otp,
					voucherCode
				};
				this.props.loginUser(sendThis, ascwToken => {
					this.ascwPopup(ascwToken);
				});
			}
		} else {
			mySwal.fire("Error", "Please enter a valid phone number, example: 125549884", "error");
		}

		// const { number, otp } = this.state;
		// const voucherCode = this.props.match.params.code;
		// const sendThis = {
		// 	number,
		// 	otp,
		// 	voucherCode
		// };

		// // console.log(sendThis);
		// this.props.loginUser(sendThis);
	};

	handleAdultLogin = e => {
		e.preventDefault();
		const voucherCode = this.props.match.params.code;
		const number = "0" + this.state.number.match(/\S/gi).join("");
		const numberRegex = /^\d+$/;

		if (numberRegex.test(number) && number.length > 9 && number.length < 12 && number.substring(0, 2) === "01")
			this.props.adultRegister({ voucherCode, number });
		else mySwal.fire("Error", "Please enter a valid phone number, example: 125549884", "error");
	};

	render() {
		if (this.props.user.error && this.props.user.error === "Invalid Endpoint.")
			window.location.href = window.location.href;
		const renderPage = () => {
			if (this.props.code.code && !this.props.code.error) {
				return (
					<div>
						<div className="mt-4 text-center">
							<h5>CONFIRM DETAILS</h5>
							<p style={{ fontSize: "12px" }} className="py-0 px-4">
								{this.props.code.sku !== "ensure" && this.props.code.sku !== "glucerna"
									? "Please confirm your details and fill in the OTP number to continue"
									: "Please fill in your phone number to continue."}
							</p>
						</div>

						<form onSubmit={this.handleAdultLogin} className="mt-5">
							<div className="form-group">
								<h6>
									<strong>PHONE NUMBER</strong>
								</h6>

								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center"
									}}
								>
									<div className="input-prefix">+60</div>
									<input
										disabled={this.props.user.action === "login"}
										type="text"
										placeholder="Enter mobile number (1XXXXXXXX)"
										id="number"
										name="number"
										className="custom-number-input"
										onChange={e =>
											this.setState({
												...this.state,
												number: e.target.value ? e.target.value.match(/\S/gi).join("") : ""
											})
										}
										value={this.state.number ? this.state.number.match(/\S/gi).join("") : ""}
										required
									/>
								</div>
							</div>

							{this.props.user.error ? (
								<div className="alert alert-warning" role="alert">
									<strong>{this.props.user.error}</strong>
								</div>
							) : null}

							<div style={{ marginTop: "5vh" }}>
								{this.props.user.isLoading ? (
									<div className="text-center">
										<div className="spinner-border text-dark" role="status">
											<span className="sr-only">Loading...</span>
										</div>
									</div>
								) : (
									<input type="submit" value="CONFIRM" className="form-control big-button btn" />
								)}
							</div>
						</form>

						<p className="mt-2 text-center">
							<small>
								For enquiries, kindly call Abbott Nutrition Centre at
								<br />
								1800-88-6233 (Monday - Friday; 9am to 6pm)
							</small>
						</p>
					</div>
				);
				// }
			} else if (!this.props.code.code && !this.props.code.error) {
				// loading
				return (
					<div className="text-center">
						<div className="spinner-border text-dark" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					</div>
				);
			} else {
				// error
				return (
					<div>
						<h1>No Code Provided</h1>
					</div>
				);
			}
		};

		if (this.props.user.isAuthenticated && this.props.user.user && !this.props.user.user.questionnaire) {
			return <Redirect to="/user/dashboard" />;
		} else if (this.props.user.isAuthenticated && this.props.user.user && this.props.user.user.questionnaire) {
			return <Redirect to="/questionnaire/ipp/ensure/start" />;
		} else {
			return <div className="body-contents mb-1">{renderPage()}</div>;
		}
	}
}

const mapStateToProps = state => {
	return {
		user: state.user,
		code: state.code
	};
};

const mapDispatchToProps = dispatch => {
	return {
		checkNumber: number => dispatch(checkNumber(number)),
		registerUser: data => dispatch(registerUser(data)),
		loginUser: (data, callback) => dispatch(loginUser(data, callback)),
		checkSession: () => dispatch(checkSession()),
		checkScanned: (data, callback) => dispatch(checkScanned(data, callback)),
		fetchCode: data => dispatch(fetchCode(data)),
		checkRegister: data => dispatch(checkRegister(data)),
		ascwLogin: data => dispatch(ascwLogin(data)),
		adultRegister: data => dispatch(adultRegister(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
