import React, { Component } from "react";
import { connect } from "react-redux";
// import { selectStore } from '../../../redux/actions/userActions';
import axios from "axios";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Select from "react-select";
import timer from "../../../assets/images/timer.png";
// import pediasure600 from "../../../assets/images/pediasure600.jpg";
// import pediasure850 from "../../../assets/images/pediasure850.jpg";
// import similac600 from "../../../assets/images/similac600.jpg";
// import similac900 from "../../../assets/images/similac900.jpg";
// import ensure850 from "../../../assets/images/ensure850.jpg";
// import glucerna850 from "../../../assets/images/glucerna850.png";
// import isomil850 from "../../../assets/images/isomil850.jpg";
// import stc820 from "../../../assets/images/stc820.jpg";

const mySwal = withReactContent(Swal);
class Redeem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			storeType: localStorage.getItem("storeType") || "",
			chainType: localStorage.getItem("chainType") || "",
			area: localStorage.getItem("area") || "",
			storeName: localStorage.getItem("storeName") || "",
			state: localStorage.getItem("state") || "",
			confirmed: false,
			sku: "",
			productName: "",
			loading: false,
			special: false,
			storeId: null
		};
	}

	componentDidMount() {
		if (
			!this.props.stores ||
			!this.props.user.brand ||
			!this.props.location ||
			!this.props.location.state ||
			!this.props.location.state.selected ||
			!this.props.location.state.promoMechanics
		) {
			this.props.history.push("/user/dashboard");
		} else {
			// if (this.props.location.selected === "400g" || this.props.location.selected === "2liq" || this.props.location.selected === "3liq") {
			if (this.props.location.state.promoMechanics !== "default") {
				const hospital = this.props.stores.hospital.filter(store => store.storeName === this.props.user.doctor.name)[0];
				const regex = /hcphospitalretaillist/gi;
				this.setState({
					...this.state,
					storeType: hospital.storeType,
					chainType: hospital.chainType,
					area: hospital.area,
					storeName: regex.test(this.props.user.doctor.code) ? hospital.location : hospital.storeName,
					state: hospital.state,
					special: true,
					storeId: hospital.id
				});
			}
		}
	}

	handleChange = e => {
		console.log(e.target.id);
		console.log(e.target.value);
		if (e.target.id === "storeType") {
			if (this.sSelect) {
				this.sSelect.state.value = null;
			}
			this.setState({
				...this.state,
				storeType: e.target.value,
				chainType: "",
				area: "",
				storeName: "",
				state: ""
			});

			localStorage.setItem("storeType", e.target.value);
			localStorage.removeItem("chainType");
			localStorage.removeItem("area");
			localStorage.removeItem("storeName");
			localStorage.removeItem("state");
		} else if (e.target.id === "chainType") {
			this.sSelect.state.value = null;
			this.setState({
				...this.state,
				chainType: e.target.value,
				area: "",
				storeName: "",
				state: ""
			});

			localStorage.setItem("chainType", e.target.value);
			localStorage.removeItem("area");
			localStorage.removeItem("storeName");
			localStorage.removeItem("state");
		} else if (e.target.id === "state") {
			this.sSelect.state.value = null;
			this.setState({
				...this.state,
				state: e.target.value,
				area: "",
				storeName: "",
				chainType: ""
			});

			localStorage.setItem("state", e.target.value);
			localStorage.removeItem("area");
			localStorage.removeItem("storeName");
			localStorage.removeItem("chainType");
		} else if (e.target.id === "area") {
			this.sSelect.state.value = null;
			this.setState({
				...this.state,
				area: e.target.value,
				storeName: ""
			});

			localStorage.setItem("area", e.target.value);
			localStorage.removeItem("storeName");
		} else if (e.target.id === "storeName") {
			this.setState({
				...this.state,
				[e.target.id]: e.target.value
			});

			localStorage.setItem(e.target.id, e.target.value);
		}
	};

	handleStore = e => {
		// console.log(e);
		console.log(e);
		console.log(e.value);
		this.setState({
			...this.state,
			storeName: e.value
		});
		localStorage.setItem("storeName", e.value);
	};

	handleRedeem = e => {
		const sku = this.props.history.location.state.selected;
		const storeId = this.state.special ? this.state.storeId : this.state.storeName;
		const brand = this.props.user.brand;
		const number = this.props.user.user.number;
		const code = this.props.user.code;

		if (sku && storeId && brand && number && code) {
			mySwal
				.fire({
					html: `
				<div class="text-center p-0 m-0">
					<img src="${timer}" alt="timer" class="img-fluid" style="width: 30%;" />
					<h2 style="font-weight: bold; font-size: 18px; margin-top: 25px;">CONFIRMATION</h2>
					<h3 style="color : #2ba25c; font-size: 16px; margin-top: 10px;">REMINDER</h3>
					<p style="margin: 0; padding: 0; font-size: 14px;">This is a one time voucher redemption code.<br />You need to redeem within 5 minutes.</p>

					<div style="background-color: #d33; padding: 15px 0px; width: 80%; margin: 20px auto; text-align: center; border-radius: 10px;">
						<p style="margin: 0; padding: 0; color: white; font-weight: bold; font-size: 20px;">5 MINUTES</p>
					</div>

					<div style="margin-top: 15px;">
						<p style="font-weight: bold; margin: 0; padding: 0;">Note :</p>
						<p style="margin: 0; padding: 0; font-size: 14px;">Show this code to the cashier when you want to redeem the ${
							this.props.user.doctor.promoMechanics === "foc" ? "FOC products" : "gift voucher"
						}.</p>
						<br />
						<p style="margin: 0; padding: 0; font-size: 14px;"><strong>DO NOT</strong> tap on the button or your gift voucher will be deemed <strong>USED</strong></p>
					</div>
				</div>
				`,
					showCancelButton: true,
					cancelButtonColor: "white",
					cancelButtonText: "USE LATER",
					confirmButtonText: "REDEEM NOW",
					confirmButtonColor: "#0a1a30",
					customClass: {
						container: "px-0",
						confirmButton: "w-100",
						cancelButton: "w-100 text-body border",
						actions: "flex-column"
					}
					// preConfirm: () => {
					// 	console.log({ sku, storeId, brand, number, code });
					// return axios
					// 	.post("/api/user/redeemNow", { sku, storeId, brand, number, code })
					// 	.then(res => {
					// 		const newUser = {
					// 			...res.data.user,
					// 			usrStatus: "generated"
					// 		};
					// 		this.props.updateUser(newUser);
					// 		this.props.upsertUser(res.data.data);
					// 		this.props.history.push("/user/checkout");
					// 		return true;
					// 	})
					// 	.catch(err => {
					// 		mySwal.showValidationMessage(err.response.data.error);
					// 	});
					// },
					// allowOutsideClick: () => !mySwal.isLoading()
				})
				.then(result => {
					if (result.value && result.isConfirmed) {
						if (!/ensure/gi.test(brand) && !/glucerna/gi.test(brand)) {
							mySwal.fire({
								text: "Did you use this brand in the last 12 months?",
								input: "radio",
								inputOptions: {
									yes: "Yes",
									no: "No"
								},
								inputValidator: val => {
									if (!val) return "Please choose an answer";
								},
								confirmButtonText: "SUBMIT",
								customClass: {
									confirmButton: "big-button btn form-control"
								},
								preConfirm: answer => {
									const sendThis = {
										number,
										answer,
										sku,
										storeId,
										brand,
										code
									};

									return axios
										.post("/api/user/redeemNow", sendThis)
										.then(res => {
											const newUser = {
												...res.data.user,
												usrStatus: "generated"
											};
											this.props.updateUser(newUser);
											this.props.upsertUser(res.data.data);
											this.props.history.push("/user/checkout");
											return true;
										})
										.catch(err => {
											mySwal.showValidationMessage(err.response.data.error);
										});

									// return this.props.loginUser(sendThis);
								},
								allowOutsideClick: () => !mySwal.isLoading()
							});
						} else {
							const sendThis = {
								number,
								sku,
								storeId,
								brand,
								code
							};
							console.log(sendThis);
							axios
								.post("/api/user/redeemNow", sendThis)
								.then(res => {
									const newUser = {
										...res.data.user,
										usrStatus: "generated"
									};
									this.props.updateUser(newUser);
									this.props.upsertUser(res.data.data);
									this.props.history.push("/user/checkout");
								})
								.catch(err => {
									mySwal.fire(err.reponse.data.error);
								});
						}
					}
				});
		} else {
			console.log(this.props);
			console.log(this.state);
			console.log({ sku, storeId, brand, number, code });
			mySwal.fire("", "Please select a store to proceed", "info");
		}
	};

	render() {
		if (
			!this.props.stores ||
			!this.props.user.brand ||
			!this.props.location ||
			!this.props.location.state ||
			!this.props.location.state.selected ||
			!this.props.location.state.promoMechanics
		) {
			return <Redirect to="/user/dashboard" />;
		} else {
			const renderOption = () => {
				if (this.state.storeType === "HS") {
					// HS
					const renderStores = this.props.stores
						? this.props.stores[this.state.storeType]
								.filter(
									store =>
										store.chainType === this.state.chainType &&
										store.area === this.state.area &&
										store.sku[this.props.user.brand] &&
										store.sku[this.props.user.brand][this.props.history.location.state.selected] === true
								)
								.map(store => {
									return { label: store.storeName, value: store.id };
								})
						: null;

					return (
						<div>
							<div className="form-group">
								<label htmlFor="chainType">STORE NAME</label>
								<select
									className="custom-select"
									id="chainType"
									name="chainType"
									onChange={this.handleChange}
									value={this.state.chainType}
									required
								>
									<option value="" disabled>
										Please select a store
									</option>
									{this.props.stores
										? this.props.stores[this.state.storeType]
												.filter(
													store => store.sku[this.props.user.brand][this.props.history.location.state.selected] === true
												)
												.map(store => store.chainType)
												.filter((x, y, z) => z.indexOf(x) === y)
												.map(type => (
													<option key={type} value={type}>
														{type}
													</option>
												))
										: null}
								</select>
							</div>

							<div className="form-group">
								<label htmlFor="area">AREA</label>
								<select
									className="custom-select"
									id="area"
									name="area"
									onChange={this.handleChange}
									value={this.state.area}
									required
								>
									<option value="" disabled>
										Please select an Area
									</option>
									{this.props.stores
										? this.props.stores[this.state.storeType]
												.filter(
													store =>
														store.chainType === this.state.chainType &&
														store.sku[this.props.user.brand][this.props.history.location.state.selected] === true
												)
												.map(store => store.area)
												.filter((x, y, z) => z.indexOf(x) === y)
												.map(area => (
													<option key={area} value={area}>
														{area}
													</option>
												))
										: null}
								</select>
							</div>

							<div className="form-group">
								<label htmlFor="storeName">LOCATION</label>
								<Select
									ref={ref => (this.sSelect = ref)}
									options={renderStores}
									onChange={this.handleStore}
									value={renderStores.find(store => store.value === this.state.storeName)}
								/>
							</div>

							{/* <div style={{ marginTop: "10vh" }}>
								<input type="submit" value="GENERATE VOUCHER" className="btn form-control big-button" />
							</div> */}
							<div style={{ marginTop: "10vh" }}>
								<div>
									<button className="big-button form-control" type="button" onClick={this.handleRedeem}>
										REDEEM NOW
									</button>
								</div>
								<div className="mt-3">
									<button
										className="big-button-alt form-control"
										type="button"
										onClick={() => this.props.history.push("/user/dashboard")}
									>
										REDEEM LATER
									</button>
								</div>
							</div>
							<div>
								<p>
									<small>
										Abbott Nutrition Careline at 1800-88-6233
										<br />
										(Monday-Friday 9am-6pm)
									</small>
								</p>
							</div>
						</div>
					);
				} else if (
					this.state.storeType === "MM" ||
					this.state.storeType === "CMH" ||
					this.state.storeType === "BS" ||
					this.state.storeType === "PH" ||
					this.state.storeType === "hospital"
				) {
					// BS , CMH , PH , MM
					const renderStores = this.props.stores
						? this.props.stores[this.state.storeType]
								.filter(
									store =>
										store.state === this.state.state &&
										store.area === this.state.area &&
										store.sku[this.props.user.brand] &&
										store.sku[this.props.user.brand][this.props.history.location.state.selected] === true &&
										!store.inpatientkit
								)
								.map(store => {
									return { label: store.storeName, value: store.id };
								})
						: null;

					return (
						<div>
							<div className="form-group">
								<label htmlFor="state">STATE</label>
								<select
									className="custom-select"
									id="state"
									name="state"
									onChange={this.handleChange}
									value={this.state.state}
									required
								>
									<option value="" disabled>
										Please select a state
									</option>
									{this.props.stores
										? this.props.stores[this.state.storeType]
												.filter(
													store =>
														store.sku[this.props.user.brand][this.props.history.location.state.selected] === true &&
														!store.inpatientkit
												)
												.map(store => store.state)
												.filter((x, y, z) => z.indexOf(x) === y)
												.map(state => (
													<option key={state} value={state}>
														{state}
													</option>
												))
										: null}
								</select>
							</div>

							<div className="form-group">
								<label htmlFor="area">AREA</label>
								<select
									className="custom-select"
									id="area"
									name="area"
									onChange={this.handleChange}
									value={this.state.area}
									required
								>
									<option value="" disabled>
										Please select an Area
									</option>
									{this.props.stores
										? this.props.stores[this.state.storeType]
												.filter(
													store =>
														store.state === this.state.state &&
														store.sku[this.props.user.brand][this.props.history.location.state.selected] === true &&
														!store.inpatientkit
												)
												.map(store => store.area)
												.filter((x, y, z) => z.indexOf(x) === y)
												.map(area => (
													<option key={area} value={area}>
														{area}
													</option>
												))
										: null}
								</select>
							</div>

							<div className="form-group">
								<label htmlFor="storeName">STORE NAME</label>
								<Select
									ref={ref => (this.sSelect = ref)}
									options={renderStores}
									onChange={this.handleStore}
									value={renderStores.find(store => store.value === this.state.storeName)}
								/>
							</div>

							{/* <div style={{ marginTop: "10vh" }}>
								<input type="submit" value="GENERATE VOUCHER" className="btn big-button form-control" />
							</div> */}
							<div style={{ marginTop: "10vh" }}>
								<div>
									<button className="big-button form-control" type="button" onClick={this.handleRedeem}>
										REDEEM NOW
									</button>
								</div>
								<div className="mt-3">
									<button
										className="big-button-alt form-control"
										type="button"
										onClick={() => this.props.history.push("/user/dashboard")}
									>
										REDEEM LATER
									</button>
								</div>
							</div>
							<div>
								<p>
									<small>
										Abbott Nutrition Careline at 1800-88-6233
										<br />
										(Monday-Friday 9am-6pm)
									</small>
								</p>
							</div>
						</div>
					);
				} else if (this.state.storeType === "EC") {
					// EC
					return (
						<div>
							<div className="form-group">
								<label htmlFor="storeName">PLATFORM</label>
								<select
									className="custom-select"
									id="storeName"
									name="storeName"
									onChange={this.handleChange}
									value={this.state.storeName}
									required
								>
									<option value="" disabled>
										Please select a store
									</option>
									{this.props.stores
										? this.props.stores[this.state.storeType].map(ecomm => (
												<option key={ecomm.id} value={ecomm.id}>
													{ecomm.storeName}
												</option>
										  ))
										: null}
								</select>
							</div>

							{/* <div style={{ marginTop: "35vh" }}>
								<input type="submit" value="GENERATE VOUCHER" className="btn big-button form-control" />
							</div> */}
							<div style={{ marginTop: "10vh" }}>
								<div>
									<button className="big-button form-control" type="button" onClick={this.handleRedeem}>
										REDEEM NOW
									</button>
								</div>
								<div className="mt-3">
									<button
										className="big-button-alt form-control"
										type="button"
										onClick={() => this.props.history.push("/user/dashboard")}
									>
										REDEEM LATER
									</button>
								</div>
							</div>
							<div>
								<p>
									<small>
										Abbott Nutrition Careline at 1800-88-6233
										<br />
										(Monday-Friday 9am-6pm)
									</small>
								</p>
							</div>
						</div>
					);
				} else {
					return null;
				}
			};

			const renderPage = () => {
				if (this.props.location.state.promoMechanics === "default") {
					return (
						<div className="mb-1">
							<div className="mt-4">
								<h4>SELECT STORE</h4>
							</div>

							<div /*onSubmit={this.handleSubmit}*/>
								<div className="form-group">
									<label htmlFor="storeType">STORE TYPE</label>
									<select
										value={this.state.storeType}
										className="custom-select"
										id="storeType"
										name="storeType"
										onChange={this.handleChange}
										required
									>
										<option value="" disabled>
											Please select a store type
										</option>
										<option value="HS">Hyper/Supermarket</option>
										<option value="MM">Mini Market</option>
										<option value="CMH">Chinese Medical Hall</option>
										<option value="BS">Baby Store</option>
										<option value="PH">Pharmacy</option>
										{this.props.stores.hospital &&
										this.props.stores.hospital.length &&
										this.props.stores.hospital.filter(
											store =>
												store.sku[this.props.user.brand] &&
												store.sku[this.props.user.brand][this.props.history.location.state.selected] === true &&
												!store.inpatientkit
										).length ? (
											<option value="hospital">Hospital Pharmacy</option>
										) : null}
										<option value="EC">E-Commerce</option>
									</select>
								</div>
								{renderOption()}
							</div>
						</div>
					);
				} else {
					return (
						<div className="mb-1">
							<div className="mt-4">
								<h4>SELECT HOSPITAL</h4>
							</div>

							<div>
								<div className="form-group">
									<label htmlFor="storeName">HOSPITAL NAME</label>
									<input
										type="text"
										id="storeName"
										name="storeName"
										className="form-control"
										disabled
										readOnly
										value={this.state.storeName}
									/>
								</div>

								<div className="form-group">
									<label htmlFor="state">STATE</label>
									<input
										type="text"
										id="state"
										name="state"
										className="form-control"
										disabled
										readOnly
										value={this.state.state}
									/>
								</div>

								<div className="form-group">
									<label htmlFor="area">AREA</label>
									<input
										type="text"
										id="area"
										name="area"
										className="form-control"
										disabled
										readOnly
										value={this.state.area}
									/>
								</div>
								<div style={{ marginTop: "10vh" }}>
									<div>
										<button className="big-button form-control" type="button" onClick={this.handleRedeem}>
											REDEEM NOW
										</button>
									</div>
									<div className="mt-3">
										<button
											className="big-button-alt form-control"
											type="button"
											onClick={() => this.props.history.push("/user/dashboard")}
										>
											REDEEM LATER
										</button>
									</div>
								</div>
								<div>
									<p>
										<small>
											Abbott Nutrition Careline at 1800-88-6233
											<br />
											(Monday-Friday 9am-6pm)
										</small>
									</p>
								</div>
							</div>
						</div>
					);
				}
			};
			// console.log("stores",this.props.stores);
			// console.log("user", this.props.user);
			return <div className="body-contents text-center">{renderPage()}</div>;
		}
	}
}

const mapStateToProps = state => {
	return {
		stores: state.user.stores,
		user: state.user.user
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateUser: data => dispatch({ type: "UPDATE_USER", payload: data }),
		upsertUser: data => dispatch({ type: "UPSERT_USER", payload: data })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Redeem);
